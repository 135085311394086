import {Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { SegmentWrapper } from '../pages/segment/SegmentWrapper'
import Validate from './Validate'

const PrivateRoutes = () => {

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Pages */}
        <Route path="/validate" element={<Validate />} />
        <Route path='/dashboard' element={<DashboardWrapper />} />
        <Route path='/broadcast/segmentation' element={<SegmentWrapper />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
