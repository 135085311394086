import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { GridLoader } from "react-spinners";
import {
  allAgents,
  allTags,
  inboxAnalytics,
  inboxAnalyticsExport,
} from "../../../../utils/api";
import DatePicker from "../date";
import { getCSSVariableValue } from "../../../assets/ts/_utils";
import { InboxTable } from "../tables/InboxTable";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

const Inbox: React.FC = () => {
  var date = new Date();
  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [inboxData, setInboxData] = useState<null | any>(null);
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState([]);
  const channels = [
    {
      label: "Whatsapp",
      value: "whatsapp",
    },
    {
      label: "Instagram",
      value: "instagram",
    },
    {
      label: "Messenger",
      value: "messenger",
    },
    {
      label: "Email",
      value: "email",
    },
  ];
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [inboxLoader, setInboxLoader] = useState(true);
  const [inboxError, setInboxError] = useState(false);
  const [ticketsData, setTicketsData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [ticketsLoader, setTicketsLoader] = useState(true);
  const [reportDownload, setReportDownload] = useState(false);

  function createData(
    createdAt: string,
    ticketsCreated: number,
    ticketsReplied: number,
    ticketsClosed: number,
    messagesReceived: number,
    messagesSent: number,
    ttfrAgent: number,
    ttr: number,
    ttrAgent: number,
    botTickets: number,
    botDeflectionRate: number,
    AgentTickets: number
  ) {
    return {
      createdAt,
      ticketsCreated,
      ticketsReplied,
      ticketsClosed,
      messagesReceived,
      messagesSent,
      ttfrAgent,
      ttr,
      ttrAgent,
      botTickets,
      botDeflectionRate,
      AgentTickets,
    };
  }

  useEffect(() => {
    allAgents().then((resp: any) => {
      let tempAgents: any = [];
      for (let i = 0; i < resp.data.length; i++) {
        tempAgents.push({
          value: resp.data[i].agent_email,
          label: resp.data[i].agent_email,
          agent_id: resp.data[i].agent_id,
        });
      }
      setAgents(tempAgents);
    });

    allTags().then((resp: any) => {
      let tempTags: any = [];
      for (let i = 0; i < resp.data.length; i++) {
        tempTags.push({
          value: resp.data[i].name,
          label: resp.data[i].name,
          tag_id: resp.data[i].id,
        });
      }
      setTags(tempTags);
    });
  }, []);

  const InboxAnalyticsAPI = () => {
    inboxAnalytics(
      format(startDate, "yyy-MM-dd"),
      format(endDate, "yyy-MM-dd"),
      selectedAgent,
      selectedChannels,
      "",
      20,
      0,
      selectedTags
    )
      .then((resp) => {
        setInboxData(resp.data[0]);
      })
      .catch((err) => {
        console.log(err);
        setInboxError(true);
      })
      .finally(() => {
        setInboxLoader(false);
      });
  };

  const ticketAnalyticsAPI = (offset: number) => {
    inboxAnalytics(
      format(startDate, "yyy-MM-dd"),
      format(endDate, "yyy-MM-dd"),
      selectedAgent,
      selectedChannels,
      "ticket_created_at",
      10,
      offset,
      selectedTags
    )
      .then((resp) => {
        setTicketsData(resp.data);
        setTicketsLoader(false);
      })
      .catch((err) => {
        setTicketsLoader(false);
        console.log(err);
      });
  };

  useEffect(() => {
    InboxAnalyticsAPI();
    setInboxLoader(true);
    ticketAnalyticsAPI(offset);
    // eslint-disable-next-line
  }, [startDate, endDate, selectedAgent, selectedChannels, selectedTags]);

  const downloadReports = () => {
    setReportDownload(true);
    inboxAnalyticsExport(
      format(startDate, "yyy-MM-dd"),
      format(endDate, "yyy-MM-dd"),
      selectedAgent,
      selectedChannels,
      selectedTags
    )
      .then((resp: any) => {
        var downloadElement = document.createElement("a");
        downloadElement.setAttribute("href", resp.data.message);
        downloadElement.setAttribute("download", "");
        downloadElement.click();
        toast.success("Downloaded reports successfully");
      })
      .catch((err: any) => {
        console.log(err);
        toast.error("Error occured. Please try again!");
      })
      .finally(() => {
        setReportDownload(false);
      });
  };

  const rows = [
    ticketsData.map((a: any) => {
      return createData(
        a.timezone,
        a.tickets_created,
        a.tickets_replied,
        a.tickets_closed,
        a.messages_received,
        a.messages_sent,
        a.median_ttfr_agent,
        a.median_ttr,
        a.median_ttr_agent,
        a.bot_tickets,
        a.bot_deflection_rate,
        a.agent_tickets
      );
    }),
  ];

  function secondsToHms(d: number) {
    // var d: number = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + "h " : "";
    var mDisplay = m > 0 ? m + "m " : "";
    var sDisplay = s > 0 ? s + "s " : d > 0 && d < 1 ? d + "s " : "0s";
    return hDisplay + mDisplay + sDisplay;
  }

  return (
    <div className="p-6 mb-9 card card-flush mx-1">
      <div className="card-header border-0 pt-0 px-0">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Inbox Analytics</span>
        </h3>

        <div className="card-toolbar">
          <div className="w-100 w-md-auto" style={{ minWidth: "250px" }}>
            <Select
              isMulti
              name="agents"
              options={channels}
              placeholder="Select channel"
              onChange={(e: any) => {
                setSelectedChannels(e.map((agent: any) => agent.value));
              }}
            />
          </div>

          <div
            className="mx-md-4 my-4 mt-my-0 w-100 w-md-auto"
            style={{ minWidth: "250px" }}
          >
            <Select
              isMulti
              name="agents"
              options={agents}
              placeholder="Select agent"
              onChange={(e: any) => {
                setSelectedAgent(e.map((agent: any) => agent.agent_id));
              }}
            />
          </div>

          <div
            className="me-md-4 my-4 mt-my-0 w-100 w-md-auto"
            style={{ minWidth: "250px" }}
          >
            <Select
              isMulti
              name="tags"
              options={tags}
              placeholder="Select tag"
              onChange={(e: any) => {
                setSelectedTags(e.map((tag: any) => tag.tag_id));
              }}
            />
          </div>

          <DatePicker
            startDate={startDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            setLoading={setInboxLoader}
            className=""
          />

          <Button
            className="ms-3"
            onClick={() => downloadReports()}
            disabled={reportDownload}
          >
            {reportDownload ? "Downloading..." : "Download report"}
          </Button>
        </div>
      </div>
      {inboxLoader ? (
        <GridLoader color={getCSSVariableValue("--kt-primary")} />
      ) : inboxError ? (
        <p className="text-center mt-5">
          An error has occured. Please try again
        </p>
      ) : (
        inboxData !== null && (
          <div>
            <div
              className="d-grid justify-content-between align-items-center mt-5"
              style={{
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                gap: 15,
              }}
            >
              <div
                className="p-3 text-end rounded"
                style={{ border: "1px solid var(--kt-text-black-60)" }}
              >
                <p className="mb-1 fs-5">Tickets created</p>
                <p className="mb-1 fs-4 fw-bold">{inboxData.tickets_created}</p>
              </div>

              <div
                className="p-3 text-end rounded"
                style={{ border: "1px solid var(--kt-text-black-60)" }}
              >
                <p className="mb-1 fs-5">Tickets replied</p>
                <p className="mb-1 fs-4 fw-bold">{inboxData.tickets_replied}</p>
              </div>

              <div
                className="p-3 text-end rounded"
                style={{ border: "1px solid var(--kt-text-black-60)" }}
              >
                <p className="mb-1 fs-5">Unassigned tickets</p>
                <p className="mb-1 fs-4 fw-bold">
                  {inboxData.unassigned_tickets}
                </p>
              </div>

              <div
                className="p-3 text-end rounded"
                style={{ border: "1px solid var(--kt-text-black-60)" }}
              >
                <p className="mb-1 fs-5">Tickets opened</p>
                <p className="mb-1 fs-4 fw-bold">{inboxData.open_tickets}</p>
              </div>

              <div
                className="p-3 text-end rounded"
                style={{ border: "1px solid var(--kt-text-black-60)" }}
              >
                <p className="mb-1 fs-5">Tickets closed</p>
                <p className="mb-1 fs-4 fw-bold">{inboxData.tickets_closed}</p>
              </div>

              <div
                className="p-3 text-end rounded"
                style={{ border: "1px solid var(--kt-text-black-60)" }}
              >
                <p className="mb-1 fs-5">Agent Tickets</p>
                <p className="mb-1 fs-4 fw-bold">{inboxData.agent_tickets}</p>
              </div>

              <div
                className="p-3 text-end rounded"
                style={{ border: "1px solid var(--kt-text-black-60)" }}
              >
                <p className="mb-1 fs-5">Messages sent</p>
                <p className="mb-1 fs-4 fw-bold">{inboxData.messages_sent}</p>
              </div>

              <div
                className="p-3 text-end rounded"
                style={{ border: "1px solid var(--kt-text-black-60)" }}
              >
                <p className="mb-1 fs-5">Messages received</p>
                <p className="mb-1 fs-4 fw-bold">
                  {inboxData.messages_received}
                </p>
              </div>

              {inboxData.median_ttr && inboxData.median_ttr !== null && (
                <div
                  className="p-3 text-end rounded"
                  style={{ border: "1px solid var(--kt-text-black-60)" }}
                >
                  <p className="mb-1 fs-5">Average TTR</p>
                  <p className="mb-1 fs-4 fw-bold">
                    &nbsp;{" "}
                    {secondsToHms(
                      Number(parseFloat(inboxData.median_ttr).toFixed(2))
                    )}
                  </p>
                </div>
              )}

              {inboxData.median_ttr_agent &&
                inboxData.median_ttr_agent !== null && (
                  <div
                    className="p-3 text-end rounded"
                    style={{ border: "1px solid var(--kt-text-black-60)" }}
                  >
                    <p className="mb-1 fs-5">Average TTR(Agent)</p>
                    <p className="mb-1 fs-4 fw-bold">
                      &nbsp;{" "}
                      {secondsToHms(
                        parseFloat(
                          Number(inboxData.median_ttr_agent).toFixed(2)
                        )
                      )}
                    </p>
                  </div>
                )}

              {inboxData.median_ttfr_agent !== null && (
                <div
                  className="p-3 text-end rounded"
                  style={{ border: "1px solid var(--kt-text-black-60)" }}
                >
                  <p className="mb-1 fs-5">Average TTFR(Agent)</p>
                  <p className="mb-1 fs-4 fw-bold">
                    &nbsp;{" "}
                    {secondsToHms(
                      parseFloat(Number(inboxData.median_ttfr_agent).toFixed(2))
                    )}
                  </p>
                </div>
              )}

              <div
                className="p-3 text-end rounded"
                style={{ border: "1px solid var(--kt-text-black-60)" }}
              >
                <p className="mb-1 fs-5">Bot Tickets</p>
                <p className="mb-1 fs-4 fw-bold">{inboxData.bot_tickets}</p>
              </div>

              <div
                className="p-3 text-end rounded"
                style={{ border: "1px solid var(--kt-text-black-60)" }}
              >
                <p className="mb-1 fs-5">Bot Deflection rate</p>
                <p className="mb-1 fs-4 fw-bold">
                  {parseFloat(inboxData.bot_deflection_rate).toFixed(2)}
                </p>
              </div>
            </div>

            {/* Day-wise tickets data  */}

            <div>
              <InboxTable
                className="mt-10"
                rows={rows}
                secondsToHms={secondsToHms}
                offset={offset}
                setOffset={setOffset}
                setTicketsLoader={setTicketsLoader}
                ticketAnalyticsAPI={ticketAnalyticsAPI}
                ticketsData={ticketsData}
                ticketsLoader={ticketsLoader}
              />
            </div>
          </div>
        )
      )}
    </div>
  );
};

export { Inbox };
