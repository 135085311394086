/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import {FC} from 'react'

type Props = {
  settingsInfo: any | null;
}

const HeaderUserMenu: FC<Props> = (settingsInfo: any) => {

  function logout() {
    return axios.post(`${process.env.REACT_APP_ORIGIN}/api/shopify/logout/`,{
      token: localStorage.getItem('authstore') // Todo
    }).then((resp)=>{
      localStorage.removeItem('authstore'); // Todo
    }).finally(()=>{
        window.location.href = `${process.env.REACT_APP_ORIGIN}/users/login`;
      }
    )
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <div className='symbol-label fs-3 bg-light-warning text-warning'>
              {settingsInfo.settingsInfo !== null && (settingsInfo.settingsInfo.first_name + ' ' + settingsInfo.settingsInfo.last_name).split(' ').map( (word: string) => word[0]).join('')}
            </div>
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {settingsInfo.settingsInfo !== null && settingsInfo.settingsInfo.first_name}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {settingsInfo.settingsInfo !== null && settingsInfo.settingsInfo.store_name}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a href={`${process.env.REACT_APP_ORIGIN}/users/profile/`} className='menu-link px-5'>
          My Profile
        </a>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <p className='menu-link px-5 my-0' onClick={()=>logout()}>
          Logout
        </p>
      </div>

    </div>
  )
}

export {HeaderUserMenu}
