import React, { useEffect, useRef, useState } from "react";
import { rmsProductCategories, rmsProductsSku } from "../../../../utils/api";
import { getCSSVariableValue } from "../../../assets/ts/_utils";
import ApexCharts, { ApexOptions } from "apexcharts";
import { useThemeMode } from "../../layout/theme-mode/ThemeModeProvider";
import DatePicker from "../date";
import { format } from "date-fns";
import { GridLoader } from "react-spinners";

type Props = {
  className: string;
};

const ProductCategory: React.FC<Props> = ({ className }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();
  const [activeTab, setActiveTab] = useState(1);

  const [productCategoriesLabels, setProductCategoriesLabels] = useState([]);
  const [productExchangeData, setProductExchangeData] = useState([]);
  const [productRefundData, setProductRefundData] = useState([]);

  const [skuCategoriesLabels, setSkuCategoriesLabels] = useState([]);
  const [skuExchangeData, setSkuExchangeData] = useState([]);
  const [skuRefundData, setSkuRefundData] = useState([]);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  var date = new Date();
  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    const refreshChart = () => {
      if (!chartRef.current) {
        return;
      }

      const chart = new ApexCharts(
        chartRef.current,
        getChartOptions(
          activeTab,
          productExchangeData,
          productRefundData,
          productCategoriesLabels,
          skuExchangeData,
          skuRefundData,
          skuCategoriesLabels
        )
      );
      if (chart) {
        chart.render();
      }

      return chart;
    };

    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [
    chartRef,
    mode,
    activeTab,
    productRefundData,
    productExchangeData,
    skuExchangeData,
    skuRefundData,
    productCategoriesLabels,
    skuCategoriesLabels
  ]);

  useEffect(() => {
    rmsProductCategories(
      format(startDate, "yyy-MM-dd"),
      format(endDate, "yyy-MM-dd")
    )
      .then((resp: any) => {
        setProductCategoriesLabels(resp.data.map((a: any) => a.product_title));
        setProductExchangeData(resp.data.map((a: any) => a.exchange_count));
        setProductRefundData(resp.data.map((a: any) => a.refund_count));
        setError(false);
      })
      .catch((err: any) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });

    rmsProductsSku(format(startDate, "yyy-MM-dd"), format(endDate, "yyy-MM-dd"))
      .then((resp) => {
        setSkuCategoriesLabels(resp.data.map((a: any) => a.product_sku));
        setSkuExchangeData(resp.data.map((a: any) => a.exchange_count));
        setSkuRefundData(resp.data.map((a: any) => a.refund_count));
        setError(false);
      })
      .catch((err: any) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [startDate, endDate]);

  const toggleActive = (e: any, tab: number) => {
    document.getElementById("product_title_btn")?.classList.remove("active");
    document.getElementById("sku_btn")?.classList.remove("active");
    e.target.classList.add("active");
    setActiveTab(tab);
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Product Category</span>
        </h3>

        {/* begin::Toolbar */}
        <div className="card-toolbar" data-kt-buttons="true">
          <span
            className="btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1"
            id="product_title_btn"
            onClick={(e) => toggleActive(e, 1)}
          >
            Product Title
          </span>

          <span
            className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1"
            id="sku_btn"
            onClick={(e) => toggleActive(e, 2)}
          >
            SKU
          </span>

          <DatePicker
            className="text-center ms-6"
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setLoading={setLoading}
          />
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "500px" }}
        >
          <GridLoader color={getCSSVariableValue("--kt-primary")} />
        </div>
      ) : error ? (
        <p style={{padding: '20px 30px'}}>Something went wrong :( Please try again.</p>
      ) : (
        <div className="card-body">
          {/* begin::Chart */}
          <div ref={chartRef} className="card-rounded-bottom"></div>
          {/* end::Chart */}
        </div>
      )}
      ;{/* end::Body */}
    </div>
  );
};

export { ProductCategory };

function getChartOptions(
  activeTab: number,
  productExchangeData: any,
  productRefundData: any,
  productCategoriesLabels: any,
  skuExchangeData: any,
  skuRefundData: any,
  skuCategoriesLabels: any
): ApexOptions {
  const borderColor = getCSSVariableValue("--kt-gray-200");

  const color1 = getCSSVariableValue("--kt-danger");
  const color1Light = getCSSVariableValue("--kt-danger-light");

  const color2 = getCSSVariableValue("--kt-primary");
  const color2Light = getCSSVariableValue("--kt-primary-light");

  const color3 = getCSSVariableValue("--kt-success");
  const color3Light = getCSSVariableValue("--kt-success-light");

  const color4 = getCSSVariableValue("--kt-warning");
  const color4Light = getCSSVariableValue("--kt-warning-light");

  const color5 = getCSSVariableValue("--kt-info");
  const color5Light = getCSSVariableValue("--kt-info-light");

  const color6 = getCSSVariableValue("--kt-secondary");
  const color6Light = getCSSVariableValue("--kt-secondary-light");

  const color7 = getCSSVariableValue("--kt-text-red-60");
  const color7Light = getCSSVariableValue("--kt-text-red-60");

  const color8 = getCSSVariableValue("--kt-text-blue-60");
  const color8Light = getCSSVariableValue("--kt-text-blue-60");

  const color9 = getCSSVariableValue("--kt-text-green-60");
  const color9Light = getCSSVariableValue("--kt-text-green-60");

  const color10 = getCSSVariableValue("--kt-text-yellow-60");
  const color10Light = getCSSVariableValue("--kt-text-yellow-60");

  const activeSeries =
    activeTab === 1
      ? [
          {
            name: "Refund",
            data: productRefundData,
          },
          {
            name: "Exchange",
            data: productExchangeData,
          },
        ]
      : activeTab === 2
      ? [
          {
            name: "Refund",
            data: skuRefundData,
          },
          {
            name: "Exchange",
            data: skuExchangeData,
          },
        ]
      : [];

  return {
    series: activeSeries,
    chart: {
      type: "bar",
      height: 500,
      stacked: true,
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 2,
      colors: [
        color1,
        color2,
        color3,
        color4,
        color5,
        color6,
        color7,
        color8,
        color9,
        color10,
        color4,
      ],
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "lighten",
          value: 0,
        },
      },
      active: {
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    xaxis: {
      categories:
        activeTab === 1
          ? productCategoriesLabels
          : activeTab === 2 && skuCategoriesLabels,
      labels: {
        style: {
          colors: getCSSVariableValue("--kt-body-color"),
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: getCSSVariableValue("--kt-body-color"),
        },
      },
    },
    legend: {
      position: "bottom",
      labels: {
        colors: getCSSVariableValue("--kt-body-color"),
      },
    },
    colors: [
      color1,
      color2,
      color3,
      color4,
      color5,
      color6,
      color7,
      color8,
      color9,
      color10,
      color4,
    ],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      colors: [
        color1Light,
        color2Light,
        color3Light,
        color4Light,
        color5Light,
        color6Light,
        color7Light,
        color8Light,
        color9Light,
        color4Light,
        color10Light,
      ],
      strokeColors: [
        color1,
        color2,
        color3,
        color4,
        color5,
        color6,
        color7,
        color8,
        color9,
        color10,
        color4,
      ],
      strokeWidth: 3,
    },
  };
}
