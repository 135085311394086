import React, { useEffect } from "react";

function SessionWrapper() {
  useEffect(() => {
    let userObject: any = localStorage.getItem("userObject");
    
    // Convert string back to object if found
    if (userObject) {
      userObject = JSON.parse(userObject);
    } else {
      userObject = {
        phone: null,
        email: null,
      };
    }

    // Now, post this message to the parent window
    window.parent.postMessage({ userInfo: userObject, topic: "PRAGMA" }, "*"); // * means you don't restrict which domain can receive this message, adjust as needed

    window.addEventListener("message", function (event) {
      console.log("message listener");
      const userObject = event.data;
      if (userObject) {
        console.log("Received userObject from parent:", userObject);
        localStorage.setItem("userObject", JSON.stringify(userObject));
      }
    });
  }, []);

  return <div></div>;
}

export default SessionWrapper;
