/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { getCSSVariableValue } from "../../../assets/ts/_utils";
import { useThemeMode } from "../../layout/theme-mode/ThemeModeProvider";
import Chart from "chart.js/auto";
import { rmsShipVsPickup } from "../../../../utils/api";
import DatePicker from "../date";
import { GridLoader } from "react-spinners";
import { format } from "date-fns";

type Props = {
  className: string;
};

const ShipVsPickupData: React.FC<Props> = ({ className }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const { mode } = useThemeMode();
  const [shipVsPickup, setShipVsPickup] = useState<string[]>([]);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  var date = new Date();
  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(new Date());

  const color1 = getCSSVariableValue("--kt-danger");
  const color2 = getCSSVariableValue("--kt-primary");
  const color3 = getCSSVariableValue("--kt-success");
  const color4 = getCSSVariableValue("--kt-warning");
  const color5 = getCSSVariableValue("--kt-info");
  const color6 = getCSSVariableValue("--kt-secondary");
  const color7 = getCSSVariableValue("--kt-text-red-60");
  const color8 = getCSSVariableValue("--kt-text-blue-60");
  const color9 = getCSSVariableValue("--kt-text-green-60");
  const color10 = getCSSVariableValue("--kt-text-yellow-60");
  const bodyColor = getCSSVariableValue("--kt-body-color");

  const colors = [
    color1,
    color2,
    color3,
    color4,
    color5,
    color6,
    color7,
    color8,
    color9,
    color10,
    color4,
  ];

  useEffect(() => {
    const refreshChart = () => {
      if (!chartRef.current) {
        return;
      }

      const chart = new Chart(chartRef.current, {
        type: "bar",
        data: {
          labels: ["Merchant Pickup", "Self Ship"],
          datasets: [
            {
              label: "Ship vs Pickup",
              data: shipVsPickup,
              backgroundColor: colors,
              borderWidth: 0,
            },
          ],
        },
        options: {
          responsive: true,
          color: bodyColor,
          scales: {
            x: {
              ticks: {
                color: bodyColor,
              },
            },
            y: {
              ticks: {
                color: bodyColor,
              },
            },
          },
          plugins: {
            legend: {
              position: "bottom",
              labels: {
                color: bodyColor,
              },
            },
          },
        },
      });

      if (chart) {
        chart.render();
      }

      return chart;
    };

    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, shipVsPickup]);

  useEffect(() => {
    rmsShipVsPickup(
      format(startDate, "yyy-MM-dd"),
      format(endDate, "yyy-MM-dd")
    )
      .then((res: any) => {
        setShipVsPickup([res.data.merchant_pickup, res.data.self_ship]);
        setError(false);
      })
      .catch((err: any) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [startDate, endDate]);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5 mb-10">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            Merchant Pickup vs Self Ship
          </span>
        </h3>

        <div className="card-toolbar">
          <DatePicker
            className="text-center"
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setLoading={setLoading}
          />
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body pt-0">
        {/* begin::Chart */}
        {loading ? (
          <div className="d-flex justify-content-center align-items-center h-100">
            <GridLoader color={getCSSVariableValue("--kt-primary")} />
          </div>
        ) : error ? (
          <p>Something went wrong :( Please try again.</p>
        ) : shipVsPickup.length > 0 ? (
          <canvas ref={chartRef} className="card-rounded-bottom"></canvas>
        ) : (
          <p>Sorry :( We couldn't find any data to represent this chart.</p>
        )}
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { ShipVsPickupData };
