import React, { useEffect, useState } from "react";
import DatePicker from "../date";
import { GridLoader } from "react-spinners";
import { getCSSVariableValue } from "../../../assets/ts/_utils";
import { fetchCheckoutData, syncCheckoutData } from "../../../../utils/api";
// import { CheckoutTable } from "../tables/CheckoutTable";
import { format } from "date-fns";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

const Checkout = () => {
  var date: any = new Date();
  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [checkoutLoader, setCheckoutLoader] = useState(true);
  const [checkoutError, setCheckoutError] = useState(false);
  // const [checkoutData, setCheckoutData] = useState(null);
  const [checkoutSumData, setCheckoutSumData] = useState<any>(null);

  useEffect(() => {
    fetchCheckoutData(
      format(startDate, "yyy-MM-dd"),
      format(endDate, "yyy-MM-dd")
    )
      .then((resp: any) => {
        setCheckoutError(false);
        // setCheckoutData(resp.data.data);
        setCheckoutSumData(resp.data.sum_data);
      })
      .catch((err: any) => {
        console.log(err);
        setCheckoutError(true);
      })
      .finally(() => {
        setCheckoutLoader(false);
      });
  }, [startDate, endDate]);

  useEffect(() => {
    const interval = setInterval(() => {
      let disableTime: any = localStorage.getItem("synctime");
      if (disableTime && disableTime !== null) {
        if (disableTime !== "") {
          if (
            new Date().getTime() - new Date(disableTime).getTime() >
            5 * 60 * 1000
          ) {
            document.getElementById("syncButton")?.removeAttribute("disabled");
          } else {
            document
              .getElementById("syncButton")
              ?.setAttribute("disabled", "true");
          }
        }
      } else {
        document.getElementById("syncButton")?.removeAttribute("disabled");
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const syncNowAPI = () => {
    setCheckoutLoader(true);
    syncCheckoutData(
      format(startDate, "yyy-MM-dd"),
      format(endDate, "yyy-MM-dd")
    )
      .then((resp: any) => {
        setCheckoutError(false);
        // setCheckoutData(resp.data.data);
        setCheckoutSumData(resp.data.sum_data);
        localStorage.setItem("synctime", date);
        document.getElementById("syncButton")?.setAttribute("disabled", "true");
      })
      .catch((err: any) => {
        console.log(err);
        setCheckoutError(true);
      })
      .finally(() => {
        setCheckoutLoader(false);
      });
  };

  const LineBar = ({
    title,
    percent1,
    percent2,
    percent1Title,
    percent2Title,
    value1,
    value2,
  }: {
    title: string;
    percent1: any;
    percent2: any;
    percent1Title: string;
    percent2Title: string;
    value1: any;
    value2: any;
  }) => {
    return (
      <div style={{ width: "95%" }}>
        <div className="d-flex justify-content-between fw-bold fs-6 text-dark opacity-75 w-100 mt-10 mb-2">
          <span className="ms-3">{title}</span>
          <span>{Number(percent1) + Number(percent2)}%</span>
        </div>

        <div className="h-8px mx-3 w-100 bg-primary bg-opacity-20 rounded d-flex">
          <OverlayTrigger
            delay={{ hide: 250, show: 300 }}
            overlay={(props: any) => (
              <Tooltip {...props}>
                {percent1Title} : {value1}
              </Tooltip>
            )}
            placement="top"
          >
            <div
              className={`bg-primary h-8px` + (percent2 == 0 ? " rounded" : "")}
              role="progressbar"
              style={{
                width: `${percent1}%`,
                borderTopLeftRadius: "0.475rem",
                borderBottomLeftRadius: "0.475rem",
              }}
            ></div>
          </OverlayTrigger>
          <OverlayTrigger
            delay={{ hide: 250, show: 300 }}
            overlay={(props: any) => (
              <Tooltip {...props}>
                {percent2Title} : {value2}
              </Tooltip>
            )}
            placement="top"
          >
            <div
              className="h-8px"
              role="progressbar"
              style={{
                width: `${percent2}%`,
                borderTopRightRadius: "0.475rem",
                borderBottomRightRadius: "0.475rem",
                background: getCSSVariableValue("--kt-success"),
              }}
            ></div>
          </OverlayTrigger>
        </div>
      </div>
    );
  };

  return (
    <div className="p-6 mb-9 card card-flush mx-1">
      <div className="card-header border-0 pt-0 px-0">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            1Checkout Analytics
          </span>
        </h3>

        <div className="card-toolbar">
          <div className="w-100 w-md-auto" style={{ minWidth: "250px" }}>
            <DatePicker
              startDate={startDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              setLoading={setCheckoutLoader}
              className=""
            />
          </div>
          <Button
            id="syncButton"
            style={{ marginLeft: "20px" }}
            onClick={() => syncNowAPI()}
          >
            Sync now
          </Button>
        </div>
      </div>
      {checkoutLoader ? (
        <GridLoader color={getCSSVariableValue("--kt-primary")} />
      ) : checkoutError ? (
        <p className="text-center mt-5">
          An error has occured. Please try again
        </p>
      ) : (
        // checkoutData !== null &&
        checkoutSumData !== null && (
          <div>
            <p className="fs-3 fw-bold">Customer Events</p>
            <div
              className="d-grid justify-content-between align-items-center mt-5"
              style={{
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                gap: 15,
              }}
            >
              <div
                className="d-grid justify-content-between align-items-center mt-5"
                style={{
                  gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                  gap: 15,
                }}
              >
                <div
                  className="p-3 text-end rounded"
                  style={{ border: "1px solid var(--kt-text-black-60)" }}
                >
                  <p className="mb-1 fs-5">Initiate Checkout (IC) </p>
                  <p className="mb-1 fs-4 fw-bold">
                    {checkoutSumData.checkout_initiated}
                  </p>
                </div>

                <div
                  className="p-3 text-end rounded"
                  style={{ border: "1px solid var(--kt-text-black-60)" }}
                >
                  <p className="mb-1 fs-5">IC to Login</p>
                  <p className="mb-1 fs-4 fw-bold">
                    {Number(checkoutSumData.ic_to_al) +
                      Number(checkoutSumData.ic_to_otp)}
                    {/* {checkoutSumData.checkout_initiated !== 0
                      ? (
                          (checkoutSumData.ic_to_al * 100) /
                          checkoutSumData.checkout_initiated
                        ).toFixed(2)
                      : 0}{" "}
                    %) */}
                  </p>
                </div>

                {/* <div
                  className="p-3 text-end rounded"
                  style={{ border: "1px solid var(--kt-text-black-60)" }}
                >
                  <p className="mb-1 fs-5">IC to OTP</p>
                  <p className="mb-1 fs-4 fw-bold">
                    {checkoutSumData.ic_to_otp}
                    {checkoutSumData.checkout_initiated !== 0
                      ? (
                          (checkoutSumData.ic_to_otp * 100) /
                          checkoutSumData.checkout_initiated
                        ).toFixed(2)
                      : 0}{" "}
                    %)
                  </p>
                </div> 
                */}

                <div
                  className="p-3 text-end rounded"
                  style={{ border: "1px solid var(--kt-text-black-60)" }}
                >
                  <p className="mb-1 fs-5">IC to Address</p>
                  <p className="mb-1 fs-4 fw-bold">
                    {checkoutSumData.ic_to_prefilled_address}
                    {/* {checkoutSumData.checkout_initiated !== 0
                      ? (
                          (checkoutSumData.ic_to_prefilled_address * 100) /
                          checkoutSumData.checkout_initiated
                        ).toFixed(2)
                      : 0}
                    %) */}
                  </p>
                </div>

                <div
                  className="p-3 text-end rounded"
                  style={{ border: "1px solid var(--kt-text-black-60)" }}
                >
                  <p className="mb-1 fs-5">Purchase count</p>
                  <p className="mb-1 fs-4 fw-bold">
                    {checkoutSumData.purchase_count}
                  </p>
                </div>
              </div>

              <div>
                <LineBar
                  title="Initiate Checkout (IC)"
                  percent1={100}
                  percent1Title="Checkout Initiated"
                  percent2={0}
                  percent2Title=""
                  value1={checkoutSumData.checkout_initiated}
                  value2={0}
                />

                <LineBar
                  title="IC to Login"
                  percent1={
                    checkoutSumData.checkout_initiated !== 0
                      ? (
                          (checkoutSumData.ic_to_al * 100) /
                          checkoutSumData.checkout_initiated
                        ).toFixed(2)
                      : 0
                  }
                  percent1Title="Auto Login"
                  percent2={
                    checkoutSumData.checkout_initiated !== 0
                      ? (
                          (checkoutSumData.ic_to_otp * 100) /
                          checkoutSumData.checkout_initiated
                        ).toFixed(2)
                      : 0
                  }
                  percent2Title="OTP"
                  value1={checkoutSumData.ic_to_al}
                  value2={checkoutSumData.ic_to_otp}
                />

                {/* <LineBar
                  title="IC to OTP"
                  percent1={
                    checkoutSumData.checkout_initiated !== 0
                      ? (
                          (checkoutSumData.ic_to_otp * 100) /
                          checkoutSumData.checkout_initiated
                        ).toFixed(2)
                      : 0
                  }
                /> */}

                <LineBar
                  title="IC to Address"
                  percent1={
                    checkoutSumData.checkout_initiated !== 0
                      ? (
                          (checkoutSumData.ic_to_prefilled_address * 100) /
                          checkoutSumData.checkout_initiated
                        ).toFixed(2)
                      : 0
                  }
                  percent1Title="IC to Address"
                  percent2={0}
                  percent2Title=""
                  value1={checkoutSumData.ic_to_prefilled_address}
                  value2={0}
                />

                <LineBar
                  title="Purchase Count"
                  percent1={
                    checkoutSumData.checkout_initiated !== 0
                      ? (
                          (checkoutSumData.purchase_count * 100) /
                          checkoutSumData.checkout_initiated
                        ).toFixed(2)
                      : 0
                  }
                  percent1Title="Total purchase count"
                  percent2={0}
                  percent2Title=""
                  value1={checkoutSumData.purchase_count}
                  value2={0}
                />
              </div>
            </div>

            <p className="fs-3 fw-bold mt-10">Checkout Performance</p>

            <div
              className="d-grid justify-content-between align-items-center mt-5"
              style={{
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                gap: 15,
              }}
            >
              <div
                className="p-3 text-end rounded"
                style={{ border: "1px solid var(--kt-text-black-60)" }}
              >
                <p className="mb-1 fs-5">COD count</p>
                <p className="mb-1 fs-4 fw-bold">{checkoutSumData.cod_count}</p>
              </div>

              <div
                className="p-3 text-end rounded"
                style={{ border: "1px solid var(--kt-text-black-60)" }}
              >
                <p className="mb-1 fs-5">Prepaid count</p>
                <p className="mb-1 fs-4 fw-bold">
                  {checkoutSumData.prepaid_count}
                </p>
              </div>

              <div
                className="p-3 text-end rounded"
                style={{ border: "1px solid var(--kt-text-black-60)" }}
              >
                <p className="mb-1 fs-5">Purchase value</p>
                <p className="mb-1 fs-4 fw-bold">
                  ₹ {checkoutSumData.purchase_value}
                </p>
              </div>

              <div
                className="p-3 text-end rounded"
                style={{ border: "1px solid var(--kt-text-black-60)" }}
              >
                <p className="mb-1 fs-5">Avg order value</p>
                <p className="mb-1 fs-4 fw-bold">
                  {checkoutSumData.average_order_value.toFixed(2)}
                </p>
              </div>

              <div
                className="p-3 text-end rounded"
                style={{ border: "1px solid var(--kt-text-black-60)" }}
              >
                <p className="mb-1 fs-5">Number of items</p>
                <p className="mb-1 fs-4 fw-bold">{checkoutSumData.num_items}</p>
              </div>

              <div
                className="p-3 text-end rounded"
                style={{ border: "1px solid var(--kt-text-black-60)" }}
              >
                <p className="mb-1 fs-5">Conversion ratio</p>
                <p className="mb-1 fs-4 fw-bold">
                  {checkoutSumData.conversion_ratio.toFixed(2)} %
                </p>
              </div>
            </div>

            {/* <CheckoutTable
              checkoutLoader={checkoutLoader}
              checkoutData={checkoutData}
            /> */}
          </div>
        )
      )}
    </div>
  );
};

export { Checkout };
