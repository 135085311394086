import React, { useEffect, useState, useRef } from "react";
import { rmsLocation } from "../../../../utils/api";
import { getCSSVariableValue } from "../../../assets/ts/_utils";
import { useThemeMode } from "../../layout/theme-mode/ThemeModeProvider";
import ApexCharts, { ApexOptions } from "apexcharts";
import DatePicker from "../date";
import { GridLoader } from "react-spinners";
import { format } from "date-fns";

type Props = {
  className: string;
};

const LocationChart: React.FC<Props> = ({ className }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();
  const [locationLabels, setLocationLabels] = useState([]);
  const [locationRefData, setLocationRefData] = useState([]);
  const [locationExcData, setLocationExcData] = useState([]);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  var date = new Date();
  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    setLoading(true);
    rmsLocation(format(startDate, "yyy-MM-dd"), format(endDate, "yyy-MM-dd"))
      .then((resp: any) => {
        setLocationLabels(resp.data.map((a: any) => a.customer_address_state));
        setLocationExcData(resp.data.map((a: any) => a.exchange_count));
        setLocationRefData(resp.data.map((a: any) => a.refund_count));
        setError(false);
      })
      .catch((err: any) => {
        setError(true);
        // console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [startDate, endDate]);

  useEffect(() => {
    const refreshChart = () => {
      if (!chartRef.current) {
        return;
      }

      const chart = new ApexCharts(
        chartRef.current,
        getChartOptions(locationExcData, locationRefData, locationLabels)
      );
      if (chart) {
        chart.render();
      }

      return chart;
    };

    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, locationExcData, locationRefData, locationLabels]);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Location based</span>
        </h3>

        <div className="card-toolbar">
          <DatePicker
            className="text-center"
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setLoading={setLoading}
          />
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "500px" }}
        >
          <GridLoader color={getCSSVariableValue("--kt-primary")} />
        </div>
      ) : error ? (
        <p style={{padding: '20px 30px'}}>Something went wrong :( Please try again.</p>
      ) : (
        <div className="card-body">
          {/* begin::Chart */}
          <div ref={chartRef} className="card-rounded-bottom"></div>
          {/* end::Chart */}
        </div>
      )}
      {/* end::Body */}
    </div>
  );
};

export { LocationChart };

function getChartOptions(
  locationExcData: any,
  locationRefData: any,
  locationLabels: any
): ApexOptions {
  const color1 = getCSSVariableValue("--kt-danger");
  const color2 = getCSSVariableValue("--kt-primary");
  const color3 = getCSSVariableValue("--kt-success");
  const color10 = getCSSVariableValue("--kt-text-yellow-60");

  return {
    series: [
      {
        name: "Refund",
        data: locationRefData,
      },
      {
        name: "Exchange",
        data: locationExcData,
      },
    ],
    chart: {
      type: "heatmap",
      height: "400",
    },
    tooltip: {
      x: {
        show: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      labels: {
        colors: getCSSVariableValue("--kt-body-color"),
      },
    },
    xaxis: {
      categories: locationLabels,
      labels: {
        style: {
          colors: getCSSVariableValue("--kt-body-color"),
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: getCSSVariableValue("--kt-body-color"),
        },
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "lighten",
          value: 0,
        },
      },
      active: {
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        radius: 0,
        useFillColorAsStroke: true,
        colorScale: {
          ranges: [
            {
              from: 1,
              to: 200,
              name: "low",
              color: color2,
            },
            {
              from: 201,
              to: 400,
              name: "medium",
              color: color3,
            },
            {
              from: 401,
              to: 600,
              name: "high",
              color: color10,
            },
            {
              from: 601,
              to: 1000,
              name: "extreme",
              color: color1,
            },
          ],
        },
      },
    },
  };
}
