import { Field, OptionGroup } from "react-querybuilder";
import {
  dateOperators,
  statusOperators,
  countOperators,
  stringOperators,
  standardOperators,
} from "./Operators";

export const fields: OptionGroup<Field>[] = [
  {
    label: "Order",
    options: [
      {
        name: "select_order",
        label: "Select Category",
        operators: standardOperators,
        placeholder: "Enter Value"
      },
      {
        name: "order_value",
        label: "Order Value",
        operators: countOperators,
        placeholder: "Enter Value"
      },
      {
        name: "stat_rto_value",
        label: "RTO Value",
        operators: countOperators,
        placeholder: "Enter Value"
      },
      {
        name: "stat_cancelled_value",
        label: "Cancelled Value",
        operators: countOperators,
        placeholder: "Enter Value"
      },
      {
        name: "stat_exchanged_value",
        label: "Exchanged Value",
        operators: countOperators,
        placeholder: "Enter Value"
      },
      {
        name: "stat_total_order_value",
        label: "Total Orders Value",
        operators: countOperators,
        placeholder: "Enter Value"
      },
      {
        name: "user_first_name",
        label: "Order first name",
        operators: stringOperators,
        placeholder: "Enter Value"
      },
      {
        name: "user_last_name",
        label: "Order last name",
        operators: stringOperators,
        placeholder: "Enter Value"
      },
      {
        name: "user_email",
        label: "Order email",
        operators: stringOperators,
        placeholder: "Enter Value"
      },
      {
        name: "user_mobile",
        label: "Order Mobile number",
        operators: stringOperators,
        placeholder: "Enter Value"
      },
      {
        name: "unique_user_id",
        label: "Order Unique user id",
        operators: stringOperators,
        placeholder: "Enter Value"
      },
      {
        name: "order_shipping_cost",
        label: "Order shipping cost",
        operators: countOperators,
        placeholder: "Enter Value"
      },
      {
        name: "order_source_name",
        label: "Order source name",
        operators: stringOperators,
        placeholder: "Enter Value"
      },
      {
        name: "order_device_id",
        label: "Order device ID",
        operators: stringOperators,
        placeholder: "Enter Value"
      },
      {
        name: "coupons_count",
        label: "Coupons count",
        operators: countOperators,
        placeholder: "Enter Value"
      },
      {
        name: "coupons_value",
        label: "Coupons Value",
        operators: countOperators,
        placeholder: "Enter Value"
      },
      {
        name: "order_item_count",
        label: "Order item count",
        operators: countOperators,
        placeholder: "Enter Value"
      },
      {
        name: "orders_category_count",
        label: "Orders category count",
        operators: countOperators,
        placeholder: "Enter Value"
      },
      {
        name: "order_status",
        label: "Order status",
        operators: statusOperators,
        valueEditorType: "select",
        placeholder: "Select Value",
        values: [
          { name: "rto", label: "RTO" },
          { name: "cancelled", label: "Cancelled" },
          { name: "exchanged", label: "Exchanged" },
          { name: "delivered", label: "Delivered" },
        ],
        defaultValue: "RTO",
      },
      {
        name: "is_order_fulfilled",
        label: "Is order fulfilled",
        operators: [{ name: "eq", label: "equals" }],
        valueEditorType: "select",
        values: [
          { name: "true", label: "True" },
          { name: "false", label: "False" },
        ],
        defaultValue: "true",
      },
      {
        name: "stat_coupons_used",
        label: "Coupons used",
        operators: stringOperators,
        placeholder: "Enter Value"
      },
      {
        name: "comment",
        label: "Comment",
        operators: stringOperators,
        placeholder: "Enter Value"
      },
    ],
  },
  {
    label: "User",
    options: [
      {
        name: "select_user",
        label: "Select Category",
        placeholder: "Enter Value",
        operators: standardOperators
      },
      {
        name: "stat_user_first_name",
        label: "User first name",
        operators: stringOperators,
        placeholder: "Enter Value",
      },
      {
        name: "stat_user_last_name",
        label: "User last name",
        operators: stringOperators,
        placeholder: "Enter Value"
      },
      {
        name: "stat_user_email",
        label: "User email",
        operators: stringOperators,
        placeholder: "Enter Value"
      },
      {
        name: "stat_user_mobile",
        label: "User mobile number",
        operators: stringOperators,
        placeholder: "Enter Value"
      },
      {
        name: "stat_unique_user_id",
        label: "Unique user id",
        operators: stringOperators,
        placeholder: "Enter Value"
      },
    ],
  },
  {
    label: "Geography",
    options: [
      {
        name: "select_geographical",
        label: "Select Category",
        placeholder: "Enter Value",
        operators: standardOperators
      },
      {
        name: "order_pincode",
        label: "Order pincode",
        operators: stringOperators,
        placeholder: "Enter Value"
      },
      {
        name: "order_city",
        label: "Order city",
        operators: stringOperators,
        placeholder: "Enter Value"
      },
      {
        name: "order_country",
        label: "Order country",
        operators: stringOperators,
        placeholder: "Enter Value"
      },
      {
        name: "order_province",
        label: "Order province",
        operators: stringOperators,
        placeholder: "Enter Value"
      },
    ],
  },
  {
    label: "Payment",
    options: [
      {
        name: "select_payment",
        label: "Select Category",
        placeholder: "Enter Value",
        operators: standardOperators
      },
      {
        name: "payment_mode",
        label: "Payment mode",
        operators: stringOperators,
        placeholder: "Enter Value"
      },
      {
        name: "payment_partner",
        label: "Payment partner",
        operators: stringOperators,
        placeholder: "Enter Value"
      },
      {
        name: "payment_currency",
        label: "Payment currency",
        operators: stringOperators,
        placeholder: "Enter Value"
      },
      {
        name: "partial_payment_amount",
        label: "Partial payment amount",
        operators: countOperators,
        placeholder: "Enter Value"
      },
      {
        name: "buyer_accepts_marketing",
        label: "Buyer accepts marketing",
        operators: stringOperators,
        placeholder: "Enter Value"
      },
    ],
  },
  {
    label: "Price",
    options: [
      {
        name: "select_price",
        label: "Select Category",
        placeholder: "Enter Value",
        operators: standardOperators
      },
      {
        name: "average_item_price",
        label: "Average item price",
        operators: countOperators,
        placeholder: "Enter Value"
      },
      {
        name: "min_item_price",
        label: "Minimum item price",
        operators: countOperators,
        placeholder: "Enter Value"
      },
      {
        name: "max_item_price",
        label: "Maximum item price",
        operators: countOperators,
        placeholder: "Enter Value"
      },
      {
        name: "min_price_item_count",
        label: "Minimum price item count",
        operators: countOperators,
        placeholder: "Enter Value"
      },
      {
        name: "max_price_item_count",
        label: "Maximum price item count",
        operators: countOperators,
        placeholder: "Enter Value"
      },
    ],
  },
  {
    label: "Date",
    options: [
      {
        name: "select_date",
        label: "Select Category",
        placeholder: "Select Date",
        operators: standardOperators
      },
      {
        name: "order_created_at",
        label: "Order created",
        operators: dateOperators,
        inputType: "date",
      },
      {
        name: "order_delivered_at",
        label: "Order delivered",
        operators: dateOperators,
        inputType: "date",
      },
      {
        name: "order_cancelled_at",
        label: "Order cancelled",
        operators: dateOperators,
        inputType: "date",
      },
      {
        name: "order_exchanged_at",
        label: "Order exchanged",
        operators: dateOperators,
        inputType: "date",
      },
      {
        name: "order_rto_at",
        label: "Order RTO",
        operators: dateOperators,
        inputType: "date",
      },
      {
        name: "stat_last_order_date",
        label: "Last order date",
        operators: dateOperators,
        inputType: "date",
      },
      {
        name: "stat_last_rto_date",
        label: "Last RTO date",
        operators: dateOperators,
        inputType: "date",
      },
      {
        name: "stat_last_broadcast_received_at",
        label: "Last broadcast received on",
        operators: dateOperators,
        inputType: "date",
      },
      {
        name: "stat_last_exchange_at",
        label: "Last exchange at",
        operators: dateOperators,
        inputType: "date",
      },
      {
        name: "stat_last_delivered_at",
        label: "Last delivered",
        operators: dateOperators,
        inputType: "date",
      },
    ],
  },
  {
    label: "Value",
    options: [
      {
        name: "select_value",
        label: "Select Category",
        placeholder: "Enter Value",
        operators: standardOperators
      },
      {
        name: "stat_last_order_value",
        label: "Last order value",
        operators: countOperators,
        placeholder: "Enter Value"
      },
      {
        name: "stat_last_rto_value",
        label: "Last RTO value",
        operators: countOperators,
        placeholder: "Enter Value"
      },
      {
        name: "stat_last_delivered_order_value",
        label: "Last cancelled value",
        operators: countOperators,
        placeholder: "Enter Value"
      },
      {
        name: "last_exchange_value",
        label: "Last exchange value",
        operators: countOperators,
        placeholder: "Enter Value"
      },
    ],
  },
  {
    label: "Count",
    options: [
      {
        name: "select_count",
        label: "Select Category",
        placeholder: "Enter Value",
        operators: standardOperators
      },
      {
        name: "stat_order_count",
        label: "Order count",
        operators: countOperators,
        inputType: "number",
        placeholder: "Enter Value"
      },
      {
        name: "stat_rto_count",
        label: "RTO count",
        operators: countOperators,
        inputType: "number",
        placeholder: "Enter Value"
      },
      {
        name: "stat_cancellation_count",
        label: "Cancellation count",
        operators: countOperators,
        inputType: "number",
        placeholder: "Enter Value"
      },
      {
        name: "stat_exchange_count",
        label: "Exchange count",
        operators: countOperators,
        inputType: "number",
        placeholder: "Enter Value"
      },
      {
        name: "stat_delivered_count",
        label: "Delivered count",
        operators: countOperators,
        inputType: "number",
        placeholder: "Enter Value"
      },
    ],
  },
  {
    label: "Product",
    options: [
      {
        name: "select_product",
        label: "Select Category",
        placeholder: "Enter Value",
        operators: standardOperators
      },
      {
        name: "product_count",
        label: "Product Count",
        operators: countOperators,
        placeholder: "Enter Value"
      },
    ],
  },
];
