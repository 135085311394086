import React, { Fragment, useEffect, useRef, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import { format } from "date-fns";

type IntrinsicAttributes = {
  startDate: any;
  setStartDate: any;
  endDate: any;
  setEndDate: any;
  setLoading: any;
};

type Props = {
  className: string;
};

const DatePicker: React.FC<IntrinsicAttributes & Props> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  className,
  setLoading,
}) => {
  const [tempStartDate, setTempStartDate] = useState(startDate);
  const [tempEndDate, setTempEndDate] = useState(endDate);
  const dateRef = useRef<HTMLDivElement | null>(null);

  const selectionRange = {
    startDate: tempStartDate,
    endDate: tempEndDate,
    key: "selection",
  };

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  });

  const handleDateChange = (ranges: any) => {
    setTempStartDate(ranges.selection.startDate);
    setTempEndDate(ranges.selection.endDate);
  };

  const hideOnEscape = (e: any) => {
    if (e.key === "Escape") {
      setOpenDate(false);
    }
  };

  const hideOnClickOutside = (e: any) => {
    if (dateRef && dateRef.current && !dateRef.current.contains(e.target)) {
      setOpenDate(false);
    }
  };

  const [openDate, setOpenDate] = useState(false);

  const handleDateApply = () => {
    setOpenDate(false);
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
    setLoading(true);
  };

  return (
    <div className={`${className} position-relative`}>
      <div className="position-relative">
        <input
          readOnly
          className="px-4 py-2 bg-transparent w-100"
          style={{
            color: "var(--kt-body-color)",
            border: "1px solid var(--kt-body-color)",
            height: "40px",
          }}
          onClick={() => setOpenDate(!openDate)}
          value={`${format(startDate, "yyy-MM-dd")} - ${format(
            endDate,
            "yyy-MM-dd"
          )}`}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          stroke="var(--kt-body-color)"
          className="bi bi-calendar"
          style={{ position: "absolute", right: "10px", top: "12px" }}
          viewBox="0 0 16 16"
        >
          <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
        </svg>
      </div>
      <div
        ref={dateRef}
        className="pb-2 bg-white"
        style={{
          position: "absolute",
          top: "40px",
          right: "0px",
          zIndex: "999",
          boxShadow: "0px 4px 10px var(--kt-body-color)",
        }}
      >
        {openDate && (
          <Fragment>
            <DateRange
              ranges={[selectionRange]}
              editableDateInputs={true}
              onChange={(e: any) => handleDateChange(e)}
              moveRangeOnFirstSelection={false}
              months={1}
              maxDate={new Date()}
            />
            <div className="d-flex flex-end">
              <button
                className="btn btn-secondary"
                onClick={() => setOpenDate(false)}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary btn-primary-active me-4 ms-4"
                onClick={() => handleDateApply()}
              >
                Apply
              </button>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default DatePicker;
