import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { generalSettings } from '../../../../utils/api'
import {KTSVG} from '../../../helpers'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'

const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-35px symbol-md-40px'

const Navbar = () => {

  const [settingsInfo, setSettingsInfo] = useState<any | null>(null);

  useEffect(()=>{
    generalSettings().then((res:any)=>{
      setSettingsInfo(res.data);
    })
  },[])

  return (
    <div className='app-navbar flex-shrink-0 align-items-center'>

      <div className="app-navbar-item ms-1 ms-lg-3">
        <a id="headwayNotificationsBtn" href="#" className="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px">
          <KTSVG path='/media/icons/bell.svg' />
        </a>
      </div>

      <div className="app-navbar-item ms-1 ms-lg-3">
        <a href={`${process.env.REACT_APP_ORIGIN}/wallet/billing/`} className="nav-link d-flex align-items-center justify-content-center btn btn-sm btn-color-muted btn-active btn-active-light-primary active">
          <KTSVG path='/media/icons/wallet.svg' />
          <span>₹ {settingsInfo !== null && settingsInfo.wallet_balance}</span>
        </a>
	    </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div  
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <div className='symbol-label fs-3 bg-light-warning text-warning text-uppercase'>
            {settingsInfo !== null && (settingsInfo.first_name + ' ' + settingsInfo.last_name).split(' ').map( (word: string) => word[0]).join('')}
          </div>
        </div>
        <HeaderUserMenu settingsInfo={settingsInfo} />
      </div>

    </div>
  )
}

export {Navbar}
