/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { GridLoader } from "react-spinners";
import { getCSSVariableValue } from "../../../assets/ts/_utils";
import { KTSVG } from "../../../helpers";

type Props = {
  className: string;
  rows: any;
  secondsToHms: any;
  offset: any;
  setOffset: any;
  setTicketsLoader: any;
  ticketAnalyticsAPI: any;
  ticketsData: any;
  ticketsLoader: boolean;
};

const InboxTable: React.FC<Props> = ({
  className,
  rows,
  secondsToHms,
  offset,
  setOffset,
  setTicketsLoader,
  ticketAnalyticsAPI,
  ticketsData,
  ticketsLoader,
}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className="card-body py-3 px-0">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table text-center table-rounded table-striped border gs-5 gy-5">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-semibold fs-6 text-center text-gray-800 border-bottom border-gray-200">
                <th className="min-w-100px text-center">Date</th>
                <th className="min-w-100px">Tickets Created </th>
                <th className="min-w-100px">Tickets Replied</th>
                <th className="min-w-100px">Agent Tickets</th>
                <th className="min-w-100px">Tickets Closed</th>
                <th className="min-w-100px">Messages Received</th>
                <th className="min-w-100px">Messages Sent</th>
                <th className="min-w-120px">Time to First Response (Agent)</th>
                <th className="min-w-120px">Time to Resolution</th>
                <th className="min-w-120px">Time to Resolution (Agent)</th>
                <th className="min-w-100px">Bot Tickets</th>
                <th className="min-w-100px">Bot Deflection Rate</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {ticketsLoader ? (
                <tr>
                  <td colSpan={12}>
                    <GridLoader
                      color={getCSSVariableValue("--kt-primary")}
                      className="my-4 mx-4 text-center text-primary"
                    />
                  </td>
                </tr>
              ) : (
                rows[0].map((row: any, i: number) => {
                  return (
                    <tr className="text-center">
                      <td className="ps-4">{row.createdAt}</td>
                      <td className="fw-bold">{row.ticketsCreated}</td>
                      <td>{row.ticketsReplied}</td>
                      <td>{row.AgentTickets}</td>
                      <td>{row.ticketsClosed}</td>
                      <td>{row.messagesReceived}</td>
                      <td>{row.messagesSent}</td>
                      <td className="fw-bold">
                        {secondsToHms(parseFloat(row.ttfrAgent).toFixed(2))}
                      </td>
                      <td>{secondsToHms(parseFloat(row.ttr).toFixed(2))}</td>
                      <td>
                        {secondsToHms(parseFloat(row.ttrAgent).toFixed(2))}
                      </td>
                      <td>{row.botTickets}</td>
                      <td>{parseFloat(row.botDeflectionRate).toFixed(2)}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        <div className="d-flex justify-content-center align-items-center mt-6">
            <div
              style={{
                cursor: offset === 0 ? "initial" : "pointer",
                color:
                  offset === 0
                    ? "var(--kt-text-gray-400)"
                    : "var(--kt-text-primary)",
              }}
              onClick={() => {
                if (offset > 0) {
                  setTicketsLoader(true);
                  ticketAnalyticsAPI(offset - 10);
                  setOffset(offset - 10);
                }
              }}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr022.svg"
                className="svg-icon-4 text-primary"
              />
            </div>
            <p className="mx-2 mb-0">
              {offset + 1}-{offset + ticketsData.length}
            </p>
            <div
              style={{
                cursor:
                  ticketsData.length > 0 && ticketsData.length === 10
                    ? "pointer"
                    : "initial",
                color:
                  ticketsData.length > 0 && ticketsData.length === 10
                    ? "var(--kt-text-primary)"
                    : "var(--kt-text-gray-400)",
              }}
              onClick={() => {
                if (ticketsData.length === 10) {
                  setTicketsLoader(true);
                  ticketAnalyticsAPI(offset + 10);
                  setOffset(offset + 10);
                }
              }}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr023.svg"
                className="svg-icon-4 text-primary"
              />
            </div>
          </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { InboxTable };
