/* eslint-disable react/jsx-no-target-blank */
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {

  return (
    <>
      <SidebarMenuItem
        href='/dashboard'
        icon='/media/svg/sidebar/insights.svg'
        title='Insights'
      />
      <SidebarMenuItem
        href={`${process.env.REACT_APP_ORIGIN}/store/orders/`}
        icon='/media/svg/sidebar/orders.svg'
        title='Orders'
      />
      <SidebarMenuItem
        href={`${process.env.REACT_APP_ORIGIN}/reports/abandoned-checkouts/`}
        icon='/media/svg/sidebar/ac.svg'
        title='Abandoned Checkout'
      />
      <SidebarMenuItem
        href={`${process.env.REACT_APP_ORIGIN}/reports/c2p-conversions/`}
        icon='/media/svg/sidebar/c2p.svg'
        title='COD to Prepaid'
      />
      <SidebarMenuItem
        href={`${process.env.REACT_APP_ORIGIN}/geo-analysis/dashboard/`}
        icon='/media/svg/sidebar/geoanalysis.svg'
        title='Geoanalysis'
      />
      <SidebarMenuItem
        href={`${process.env.REACT_APP_INBOX_ORIGIN}/validate/?token=${localStorage.getItem('authstore')}`}
        icon='/media/svg/sidebar/inbox.svg'
        title='Inbox'
      />
      <SidebarMenuItem
        href={`${process.env.REACT_APP_ORIGIN}/connect/broadcast/`}
        icon='/media/svg/sidebar/broadcast.svg'
        title='Broadcast'
      />
      <SidebarMenuItem
        href={`${process.env.REACT_APP_ORIGIN}/reports/ndrs/`}
        icon='/media/svg/sidebar/ndr.svg'
        title='Non Delivery Reports'
      />
      <SidebarMenuItem
        href={`${process.env.REACT_APP_ORIGIN}/reports/nps/`}
        icon='/media/svg/sidebar/nps.svg'
        title='Net Promoter Scores'
      />
      <SidebarMenuItem
        href={`${process.env.REACT_APP_ORIGIN}/return-center/requests/`}
        icon='/media/svg/sidebar/returns.svg'
        title='Returns Management'
      />
      <SidebarMenuItem
        href={`${process.env.REACT_APP_ORIGIN}/chatbot/all/`}
        icon='/media/svg/sidebar/bot.svg'
        title='Chatbots'
      />
      <SidebarMenuItem
        href={`${process.env.REACT_APP_ORIGIN}/settings/`}
        icon='/media/svg/sidebar/settings.svg'
        title='Settings'
      />
    </>
  )
}

export {SidebarMenuMain}
