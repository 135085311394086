import { useEffect, useState } from "react";
import { QueryBuilderDnD } from "@react-querybuilder/dnd";
import * as ReactDnD from "react-dnd";
import * as ReactDndHtml5Backend from "react-dnd-html5-backend";
import {
  ActionWithRulesAndAddersProps,
  FieldSelectorProps,
  formatQuery,
  RuleGroupTypeIC,
  RuleType,
  ValueSelector,
  QueryBuilder,
  CombinatorSelectorProps,
  defaultValidator,
  add,
} from "react-querybuilder";
import { fields } from "./Fields";
import "react-querybuilder/dist/query-builder.scss";
import "./index.css";
import { QueryBuilderBootstrap } from "@react-querybuilder/bootstrap";
import "bootstrap-icons/font/bootstrap-icons.scss";
import { downloadCSV, pollingResults, rulesSubmit } from "../../../utils/api";
// import { CustomValueEditor } from "./CustomEditor";
import Select, { StylesConfig } from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { v4 as uuidv4 } from "uuid";
import { Modal } from "react-bootstrap";

const initialQuery: RuleGroupTypeIC = { rules: [] };

const combinators = [
  { name: "and", label: "AND", type: "rule" },
  { name: "or", label: "OR", type: "rule" },
  { name: "minus", label: "MINUS", type: "rule" },
  { name: "plus", label: "PLUS", type: "rule" },
  { name: "common", label: "COMMON", type: "group" },
  { name: "include", label: "INCLUDE", type: "group" },
  { name: "exclude", label: "EXCLUDE", type: "group" },
];

const CombinatorSelector = (props: CombinatorSelectorProps) => {
  const ruleCombinators = combinators.filter((a) => a.type === "rule");

  const groupCombinators = combinators.filter((a) => a.type === "group");

  const filteredCombinators =
    props.path?.length > 1 ? ruleCombinators : groupCombinators;

  return <ValueSelector {...{ ...props, options: filteredCombinators }} />;
};

const FilteredFieldSelector = (props: FieldSelectorProps) => {
  const filteredFields = fields.find((optGroup: any) =>
    optGroup.options.map((og: any) => og.name).includes(props.value!)
  )!.options;

  return <ValueSelector {...{ ...props, options: filteredFields }} />;
};

const AddRuleButtons = (props: ActionWithRulesAndAddersProps) => {
  const [optionSelected, setOptionSelected] = useState(null);
  // const [editBool, setEditBool] = useState<boolean>(false);
  const fieldValues = fields.map((og: any) => og.label);
  // const inputRef = useRef<HTMLInputElement | null>(null);

  const selectOptions = [];

  for (let z = 0; z < fieldValues.length; z++) {
    selectOptions.push({
      label: `${fieldValues[z]}`,
      value: fieldValues[z],
    });
  }

  const colourStyles: StylesConfig = {
    control: (styles) => ({ ...styles, border: "none" }),
    input: (styles) => ({ ...styles, width: "100px", border: "none" }),
    option: (styles) => ({ ...styles, color: "black" }),
  };

  return (
    <div className="addRule-section">
      <div className="position-relative">
        {/* <input
          defaultValue={`Group ${props.context.groupNumber}`}
          style={{
            border: "none",
            padding: "10px 16px 10px 10px",
            maxWidth: "100px",
            height: "35px",
            marginTop: "3px",
          }}
          ref={inputRef}
        />
        <i
          className="bi bi-pen cursor-pointer text-primary mx-5"
          onClick={() => {
            if (inputRef !== null && inputRef?.current !== null) {
              inputRef.current.focus();
              console.log("yooo", props.context.groupNumber);
            }
          }}
        >
          Edit
        </i> */}
      </div>
      <div style={{ marginTop: "-25px" }}>
        <label className="mb-2 fw-bold">Filter based on</label>
        <Select
          className="rule-fields main-rules"
          options={selectOptions}
          placeholder="Select Condition"
          isSearchable
          isDisabled={props.context.applying || props.context.processCompleted}
          isClearable={optionSelected !== null ? true : false}
          value={{
            label:
              optionSelected !== null
                ? `${optionSelected}`
                : "Select condition",
            value:
              optionSelected !== null
                ? `${optionSelected}`
                : "Select condition",
          }}
          styles={colourStyles}
          onChange={(event: any) => {
            if (!event) {
              setOptionSelected(null);
              return;
            }
            setOptionSelected(event.label);
          }}
        />
      </div>
      {optionSelected !== null && (
        <button
          className="btn btn-sm bg-primary text-white ms-4"
          onClick={(event: any) => {
            props.handleOnClick(event, optionSelected);
            setOptionSelected(null);
          }}
          disabled={props.context.applying || props.context.processCompleted}
        >
          + Add Condition
        </button>
      )}
    </div>
  );
};

const onAddRule = (
  rule: RuleType,
  _pP: number[],
  _q: RuleGroupTypeIC,
  context: string
) => ({
  ...rule,
  context,
  field: fields.find((optGroup: any) => optGroup.label === context)!.options[0]
    .name,
});

var missingFields: number = 0;

function getEmptyValues(list: any) {
  list.forEach(function (item: any) {
    if (item.value === "") {
      missingFields += 1;
    }
    if (item.rules) {
      getEmptyValues(item.rules);
    }
  });
  return missingFields;
}

function getEmptyOperators(list: any) {
  list.forEach(function (item: any) {
    if (item.operator === "select") {
      missingFields += 1;
    }
    if (item.rules) {
      getEmptyOperators(item.rules);
    }
  });
  return missingFields;
}

function getEmptyFields(list: any) {
  list.forEach(function (item: any) {
    if (item.field && item.field.includes("select")) {
      missingFields += 1;
    }
    if (item.rules) {
      getEmptyFields(item.rules);
    }
  });
  return missingFields;
}

export const SegmentWrapper = () => {
  const [query, setQuery]: any = useState(initialQuery);
  const [buildQuery, setBuildQuery]: any[] = useState([]);
  const [pollResponse, setPollResponse]: any = useState({});
  const [applying, setApplying] = useState<boolean>(false);
  const [processCompleted, setProcessCompleted] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [filterName, setFilterName] = useState("");
  const [groupNumber, setGroupNumber] = useState(0);
  const [stickyBar, setStickyBar] = useState(false);
  const [filterId, setFilterId] = useState("");

  const ruleRemoveButtons = document.getElementsByClassName(
    "rule-remove"
  ) as HTMLCollectionOf<HTMLButtonElement>;
  const groupRemoveButtons = document.getElementsByClassName(
    "ruleGroup-remove"
  ) as HTMLCollectionOf<HTMLButtonElement>;
  const ruleGroup = document.getElementsByClassName(
    "ruleGroup"
  ) as HTMLCollectionOf<HTMLDivElement>;
  const ruleGroupQuery = document.querySelectorAll(
    ".ruleGroup-body .ruleGroup"
  );

  const listenScrollEvent = () => {
    if (window.scrollY > 200) {
      setStickyBar(true);
    } else {
      setStickyBar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  useEffect(() => {
    if (ruleRemoveButtons && ruleRemoveButtons !== null) {
      for (var i: number = 0; i < ruleRemoveButtons.length; i++) {
        if (Object.keys(pollResponse).length > 0 && buildQuery.length > 0) {
          buildQuery[0].rules.forEach(function (item: any) {
            if (item.rules && item.rules.length > 0) {
              for (let y: number = 0; y < pollResponse.steps_data.length; y++) {
                for (let x: number = 0; x < item.rules.length; x++) {
                  if (
                    item.rules &&
                    item.rules[x].id == pollResponse.steps_data[y].step_id &&
                    !pollResponse.steps_data[y].is_query_completed
                  ) {
                    ruleRemoveButtons[i].innerHTML =
                      "<span className='spinner-border spinner-border-sm ms-2' style='color: #fff'></span>";
                    ruleRemoveButtons[i].classList.remove("btn-danger");
                    ruleRemoveButtons[i].style.border = "1px solid #f1c40f";
                    ruleRemoveButtons[i].style.background = "#f1c40f";
                    ruleRemoveButtons[i].style.color = "#fff";
                    ruleRemoveButtons[i].disabled = true;
                    ruleRemoveButtons[i].title = "";
                  } else if (
                    item.rules &&
                    item.rules[x].id == pollResponse.steps_data[y].step_id &&
                    pollResponse.steps_data[y].is_query_completed
                  ) {
                    ruleRemoveButtons[i].innerHTML =
                      "<i class='bi bi-check' style='color: white; font-size: 20px; margin-top: -2px;'></i>";
                    ruleRemoveButtons[i].classList.remove("btn-danger");
                    ruleRemoveButtons[i].style.border = "1px solid #07bc0c";
                    ruleRemoveButtons[i].style.background = "#07bc0c";
                    ruleRemoveButtons[i].style.color = "#fff";
                    ruleRemoveButtons[i].disabled = true;
                    ruleRemoveButtons[i].title = "";
                  } else if (
                    item.rules &&
                    item.rules[x].field === "order_created_at"
                  ) {
                    ruleRemoveButtons[i].innerHTML =
                      "<i class='bi bi-check' style='color: white; font-size: 20px; margin-top: -2px;'></i>";
                    ruleRemoveButtons[i].classList.remove("btn-danger");
                    ruleRemoveButtons[i].style.border = "1px solid #07bc0c";
                    ruleRemoveButtons[i].style.background = "#07bc0c";
                    ruleRemoveButtons[i].style.color = "#fff";
                    ruleRemoveButtons[i].disabled = true;
                    ruleRemoveButtons[i].title = "";
                  } else {
                    ruleRemoveButtons[i].innerHTML =
                      "<span className='spinner-border spinner-border-sm ms-2' style='color: #fff'></span>";
                    ruleRemoveButtons[i].classList.remove("btn-danger");
                    ruleRemoveButtons[i].style.border = "1px solid #f1c40f";
                    ruleRemoveButtons[i].style.background = "#f1c40f";
                    ruleRemoveButtons[i].style.color = "#fff";
                    ruleRemoveButtons[i].disabled = true;
                    ruleRemoveButtons[i].title = "";
                  }
                }
              }
            }
          });
        } else {
          ruleRemoveButtons[i].innerHTML = "<i class='fas fa-trash-alt'></i>";
          // ruleRemoveButtons[i].setAttribute(
          //   "style",
          //   "border:  1px solid var(--kt-text-red-100) !important"
          // );
          ruleRemoveButtons[i].classList.add(
            "btn",
            "btn-sm",
            "btn-light-danger"
          );
        }
      }
    }

    if (groupRemoveButtons && groupRemoveButtons !== null) {
      for (var j: number = 0; j < groupRemoveButtons.length; j++) {
        if (Object.keys(pollResponse).length > 0 && buildQuery.length > 0) {
          buildQuery[0].rules.forEach(function (item: any) {
            for (let l: number = 0; l < pollResponse.group_data.length; l++) {
              if (item.id == pollResponse.group_data[l].group_id) {
                if (pollResponse.group_data[l].is_all_steps_completed) {
                  groupRemoveButtons[j].disabled = true;
                  ruleGroupQuery[j].classList.remove("ruleGroup-progress");
                  ruleGroupQuery[j].classList.add("ruleGroup-completed");
                } else {
                  groupRemoveButtons[j].disabled = true;
                  ruleGroupQuery[j].classList.add("ruleGroup-progress");
                }
              }
            }
          });
        } else {
          groupRemoveButtons[j].innerHTML =
            "<i class='fas fa-trash-alt'></i> <span class='ms-1 fs-6'>Group</span>";
          groupRemoveButtons[j].setAttribute(
            "style",
            "border:  1px solid var(--kt-text-red-100) !important"
          );
        }
        groupRemoveButtons[j].classList.add(
          "btn",
          "btn-sm",
          "btn-light-danger",
          "d-flex",
          "align-items-center"
        );
      }
    }

    var notToggleLabel = document.getElementsByClassName("form-check-label");

    if (notToggleLabel && notToggleLabel !== null) {
      for (var k: number = 0; k < notToggleLabel.length; k++) {
        notToggleLabel[k].innerHTML = "Except";
      }
    }
  }, [query, pollResponse]);

  const pollingFn = (filter_id: string) => {
    setTimeout(() => {
      pollingResults(filter_id).then((resp: any) => {
        if (!resp.data.data.filter_data[0].is_filtering_completed) {
          setPollResponse(resp.data.data);
          pollingFn(filter_id);
          ruleGroup[0].style.border = "1px solid #f1c40f";
        } else {
          setApplying(false);
          setProcessCompleted(true);
          ruleGroup[0].style.border = "1px solid #07bc0c";
        }
      });
    }, 2000);
  };

  const addNewGroup = () => {
    setQuery(add(query, { rules: [] }, []));
    setGroupNumber(groupNumber + 1);
  };

  const buildQueryAPI = () => {
    const custom_id = uuidv4();
    setFilterId(custom_id);
    missingFields = 0;
    const emptyValues: number = getEmptyValues(query.rules);
    const emptyOperators: number = getEmptyOperators(query.rules);
    const emptyFields: number = getEmptyFields(query.rules);

    if (emptyValues + emptyOperators + emptyFields <= 0) {
      var dateValues: any[] = [];
      var tempQuery: any = JSON.parse(formatQuery(query, "json"));
      var order_created_on_rule: any;

      for (var i: number = 0; i < ruleRemoveButtons.length; i++) {
        ruleRemoveButtons[i].innerHTML =
          "In Progress <span class='spinner-border spinner-border-sm ms-2' style='color: #fff'></span>";
        ruleRemoveButtons[i].classList.remove("btn-danger");
        ruleRemoveButtons[i].style.border = "1px solid #f1c40f";
        ruleRemoveButtons[i].style.background = "#f1c40f";
        ruleRemoveButtons[i].style.color = "#fff";
        ruleRemoveButtons[i].disabled = true;
      }

      for (var j: number = 0; j < groupRemoveButtons.length; j++) {
        ruleGroupQuery[j].classList.add("ruleGroup-progress");
        groupRemoveButtons[j].disabled = true;
      }

      ruleGroup[0].style.border = "1px solid #f1c40f";

      tempQuery.rules.forEach(function (item: any) {
        if (item.rules && item.rules.length > 0) {
          for (let i: any = 0; i < item.rules.length; i++) {
            if (
              item.rules &&
              item.rules[i].field ===
                ("order_created_at" ||
                  "last_order_date" ||
                  "last_rto_at" ||
                  "last_cancelled_at" ||
                  "last_exchange_at" ||
                  "last_delivered_at")
            ) {
              dateValues.push(i);
            }
            if (item.rules && item.rules[i].field === "order_created_at") {
              order_created_on_rule = item.rules[i];
            }
            item.isDate = dateValues.length > 0 ? true : false;
            item.dateValues = dateValues;
            if (item.rules && item.rules[i].operator === "ov") {
              item.order_created_on_date = order_created_on_rule;
            }
          }
        }
        dateValues = [];
        order_created_on_rule = {};
        tempQuery.custom_id = custom_id;
        tempQuery.audience_name = filterName;
      });
      setApplying(true);
      console.log(tempQuery);
      setBuildQuery([...buildQuery, tempQuery]);
      rulesSubmit(tempQuery).then((resp) => {
        if (resp.status === 201) {
          pollingResults(tempQuery.custom_id).then((resp: any) => {
            setPollResponse(resp.data.data);
            if (!resp.data.data.filter_data[0].is_filtering_completed) {
              pollingFn(tempQuery.custom_id);
            }
          });
        }
      });
    } else {
      if (emptyValues > 0) {
        toast.error("Please fill all the value(s)");
        if (emptyOperators > 0) {
          toast.error("Please select all the operator(s)");
        }
        if (emptyFields > 0) {
          toast.error("Please select the field(s)");
        }
      } else if (emptyOperators > 0) {
        toast.error("Please select all the operator(s)");
        if (emptyFields > 0) {
          toast.error("Please select the field(s)");
        }
        if (emptyValues > 0) {
          toast.error("Please fill all the value(s)");
        }
      } else if (emptyFields > 0) {
        toast.error("Please select the field(s)");
        if (emptyValues > 0) {
          toast.error("Please fill all the value(s)");
        }
        if (emptyOperators > 0) {
          toast.error("Please select all the operator(s)");
        }
      }
    }
  };

  const NullComponent = () => null;

  useEffect(() => {
    setBuildQuery([]);
  }, [query]);

  return (
    <div>
      {/* Toasts initialisation */}

      <ToastContainer />

      {/* Title with Bread crumbs */}

      <div
        id="kt_app_toolbar"
        className="app-toolbar py-3 py-lg-6 px-6 px-lg-12"
      >
        <div
          id="kt_app_toolbar_container"
          className="container-fluid d-flex flex-column flex-md-row justify-content-between align-items-center"
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
              Segmentation
            </h1>
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="/" className="text-muted text-hover-primary">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px"></span>
              </li>
              <li className="breadcrumb-item text-muted">Segmentation</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="position-relative">
        {!processCompleted ? (
          <div
            className="d-flex position-sticky top-0 px-10 px-lg-16 py-5 pt-0"
            style={{
              zIndex: 99,
              background: stickyBar ? "#fff" : "transparent",
            }}
          >
            <input
              type="text"
              placeholder="Name of audience list"
              className="mt-5 px-5"
              style={{
                border: stickyBar
                  ? "1px solid var(--kt-primary)"
                  : "1px solid #fff",
                borderRadius: "0.425rem",
              }}
              value={filterName}
              onChange={(e: any) => setFilterName(e.target.value)}
            />
            <button
              className="btn btn-primary d-block mt-5 ms-5"
              onClick={() => buildQueryAPI()}
              disabled={
                applying ||
                filterName === null ||
                filterName === "" ||
                query === initialQuery
              }
            >
              {applying ? (
                <div>
                  Filtering...{" "}
                  <span
                    className="spinner-border spinner-border-sm ms-2"
                    style={{ color: "#fff" }}
                  ></span>{" "}
                </div>
              ) : (
                "Filter Audience"
              )}
            </button>
            <button
              className="btn btn-outline-primary btn-outline mt-5 ms-5"
              onClick={() => {
                setQuery(initialQuery);
              }}
              disabled={query === initialQuery}
            >
              Clear Filters
            </button>
            <button
              className="btn btn-outline btn-outline-primary ms-auto mt-5 d-flex align-items-center"
              style={{ background: "#fff" }}
              onClick={() => addNewGroup()}
            >
              <i className="bi bi-plus-circle me-1"></i> Group
            </button>
          </div>
        ) : (
          <div className="d-flex flex-end px-10 px-lg-16">
            <button
              className="btn btn-outline-primary btn-outline"
              onClick={() => {
                setModalOpen(true);
              }}
            >
              + Create New Audience
            </button>
            <button
              className="btn btn-primary ms-4 fs-6"
              onClick={() =>
                downloadCSV(filterId)
                  .then(() => {
                    toast.success("Your data will be downloaded shortly");
                  })
                  .catch((err) => {
                    console.log(err, "Download Error");
                    toast.error(
                      "Sorry for the inconvenience. Please try again in sometime"
                    );
                  })
              }
            >
              Download{" "}
              <i
                className="bi bi-download"
                style={{
                  color: "#fff",
                  marginTop: "-6px",
                  fontSize: "18px",
                  marginLeft: "10px",
                }}
              ></i>
            </button>
          </div>
        )}

        {/* Query Builder */}

        <div className="px-10 px-lg-16">
          {query !== initialQuery ? (
            <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
              <QueryBuilderBootstrap>
                <QueryBuilder
                  fields={fields}
                  query={query}
                  onQueryChange={(qIC: any) => setQuery(qIC)}
                  validator={defaultValidator}
                  resetOnFieldChange
                  independentCombinators
                  resetOnOperatorChange
                  combinators={combinators}
                  showNotToggle
                  context={{
                    applying,
                    processCompleted,
                    groupNumber,
                  }}
                  getValueEditorSeparator={(fld, op) => (
                    <span className="mx-4">and</span>
                  )}
                  disabled={applying || processCompleted}
                  showCombinatorsBetweenRules
                  getDefaultOperator={(field: string) => "select"}
                  controlElements={{
                    addRuleAction: AddRuleButtons,
                    fieldSelector: FilteredFieldSelector,
                    combinatorSelector: CombinatorSelector,
                    addGroupAction: NullComponent,
                    // valueEditor: CustomValueEditor,
                  }}
                  onAddRule={onAddRule}
                />
              </QueryBuilderBootstrap>
            </QueryBuilderDnD>
          ) : (
            <div className="text-center mt-20">
              <h2>Start by adding a group</h2>
              <p>Create custom filters for audience segmentation</p>
            </div>
          )}
        </div>
      </div>

      <Modal
        className="modal-sticky"
        aria-hidden="true"
        tabIndex={-1}
        show={modalOpen}
        backdrop={true}
        centered
        onHide={() => setModalOpen(false)}
        animation={true}
      >
        {/* begin::Header */}
        <div className="modal-header py-lg-4">
          <h2>Discard audience?</h2>
          {/* begin::Close */}
          <div
            className="btn btn-sm btn-icon btn-active-color-primary fs-1"
            onClick={() => setModalOpen(false)}
          >
            &times;
          </div>
          {/* end::Close */}
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="modal-body py-lg-2 px-lg-6">
          <p className="fs-4">
            Current filters will not be saved, please download audience before
            moving on.
          </p>
        </div>
        {/* begin::Body */}

        {/* begin::Footer */}
        <div className="modal-footer py-lg-4 px-lg-6">
          <button className="btn btn-outline-primary btn-outline">
            Download
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              setQuery(initialQuery);
              setProcessCompleted(false);
              setModalOpen(false);
            }}
          >
            Yes, Discard
          </button>
        </div>
        {/* begin::Footer */}
      </Modal>
    </div>
  );
};
