/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { getCSSVariableValue } from "../../../assets/ts/_utils";
import { useThemeMode } from "../../layout/theme-mode/ThemeModeProvider";
import Chart from "chart.js/auto";
import { rmsReasonSplit } from "../../../../utils/api";
import DatePicker from "../date";
import { format } from "date-fns";
import { GridLoader } from "react-spinners";

type Props = {
  className: string;
};

const ReasonSplit: React.FC<Props> = ({ className }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const { mode } = useThemeMode();
  const [activeTab, setActiveTab] = useState(1);

  const [reasonSplit, setReasonSplit] = useState([]);
  const [reasonSplitLabels, setReasonSplitLabels] = useState([]);

  const [excReasonSplit, setExcReasonSplit] = useState([]);
  const [refReasonSplit, setRefReasonSplit] = useState([]);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  var date = new Date();
  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    const refreshChart = () => {
      if (!chartRef.current) {
        return;
      }
  
      const color1 = getCSSVariableValue("--kt-danger");
      const color2 = getCSSVariableValue("--kt-primary");
      const color3 = getCSSVariableValue("--kt-success");
      const color4 = getCSSVariableValue("--kt-warning");
      const color5 = getCSSVariableValue("--kt-info");
      const color6 = getCSSVariableValue("--kt-secondary");
      const color7 = getCSSVariableValue("--kt-text-red-60");
      const color8 = getCSSVariableValue("--kt-text-blue-60");
      const color9 = getCSSVariableValue("--kt-text-green-60");
      const color10 = getCSSVariableValue("--kt-text-yellow-60");
      const bodyColor = getCSSVariableValue("--kt-body-color");
  
      const colors = [
        color1,
        color2,
        color3,
        color4,
        color5,
        color6,
        color7,
        color8,
        color9,
        color10,
        color4,
      ];
  
      const chart = new Chart(chartRef.current, {
        type: "pie",
        data: {
          labels: reasonSplitLabels,
          datasets: [
            {
              label: "Reason Split",
              data:
                activeTab === 1
                  ? reasonSplit
                  : activeTab === 2
                  ? excReasonSplit
                  : refReasonSplit,
              backgroundColor: colors,
              borderWidth: 0,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          color: bodyColor,
          plugins: {
            legend: {
              position: "bottom",
            },
          },
        },
      });
  
      if (chart) {
        chart.render();
      }
  
      return chart;
    };

    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode, reasonSplit, excReasonSplit, refReasonSplit, activeTab, reasonSplitLabels]);

  useEffect(() => {
    rmsReasonSplit(format(startDate, "yyy-MM-dd"), format(endDate, "yyy-MM-dd"))
      .then((res: any) => {
        setReasonSplit(res.data.map((a: any) => a.total_count));
        setExcReasonSplit(res.data.map((a: any) => a.exchange_count));
        setRefReasonSplit(res.data.map((a: any) => a.refund_count));
        setReasonSplitLabels(res.data.map((a: any) => a.customer_reason_label));
        setError(false);
      })
      .catch((err: any) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [startDate, endDate]);

  const toggleActive = (e: any, tab: number) => {
    document.getElementById("all_btn")?.classList.remove("active");
    document.getElementById("exchange_btn")?.classList.remove("active");
    document.getElementById("refund_btn")?.classList.remove("active");
    e.target.classList.add("active");
    setActiveTab(tab);
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5 mb-10">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Reason Split</span>
        </h3>

        {/* begin::Toolbar */}
        <div className="card-toolbar" data-kt-buttons="true">
          <DatePicker
            className="me-4"
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setLoading={setLoading}
          />

          <a
            className="btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1"
            id="all_btn"
            onClick={(e) => toggleActive(e, 1)}
          >
            All
          </a>

          <a
            className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1"
            id="exchange_btn"
            onClick={(e) => toggleActive(e, 2)}
          >
            Exchange
          </a>

          <a
            className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4"
            id="refund_btn"
            onClick={(e) => toggleActive(e, 3)}
          >
            Refund
          </a>
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body pt-0" style={{ height: "500px" }}>
        {/* begin::Chart */}
        {loading ? (
          <div className="d-flex justify-content-center align-items-center h-100">
            <GridLoader color={getCSSVariableValue("--kt-primary")} />
          </div>
        ) : error ? (
          <p>Something went wrong :( Please try again.</p>
        ) : (
          <canvas ref={chartRef} className="card-rounded-bottom"></canvas>
        )}
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { ReasonSplit };
