import Axios from "axios";
import { setupCache } from "axios-cache-interceptor";

const axios = setupCache(Axios);

axios.interceptors.request.use(function (config: any) {
  config.headers.Authorization = localStorage.getItem("authstore");
  return config;
});

export function generalSettings() {
  return axios.get(
    `${
      process.env.REACT_APP_BASE_INSIGHTS_URL
    }/merchants/merchant_details/?token=${localStorage.getItem("authstore")}`
  );
}

// Inbox Insights

export function inboxAnalytics(
  startDate: string,
  endDate: string,
  agents: any,
  channels: any,
  groupBy: any,
  limit: number,
  offset: number,
  tags: any
) {
  return axios.get(
    `${
      process.env.REACT_APP_BASE_INSIGHTS_URL
    }/inbox_analytics/stats/?from_date=${startDate}&group_by=${groupBy}&to_date=${endDate}&token=${localStorage.getItem(
      "authstore"
    )}&agents=${agents}&channels=${channels}&limit=${limit}&offset=${offset}&tags=${tags}`
  );
}

export function inboxAnalyticsExport(
  startDate: string,
  endDate: string,
  agents: any,
  channels: any,
  tags: any
) {
  return axios.get(
    `${
      process.env.REACT_APP_BASE_INSIGHTS_URL
    }/inbox_analytics/stats/export/?from_date=${startDate}&to_date=${endDate}&token=${localStorage.getItem(
      "authstore"
    )}&agents=${agents}&channels=${channels}&tags=${tags}`
  );
}

export function allAgents() {
  return axios.get(
    `${
      process.env.REACT_APP_BASE_INSIGHTS_URL
    }/inbox_analytics/agents?token=${localStorage.getItem("authstore")}`
  );
}

export function allTags() {
  return axios.get(
    `${
      process.env.REACT_APP_BASE_INSIGHTS_URL
    }/inbox_analytics/tags?token=${localStorage.getItem("authstore")}`
  );
}

// RMS Insights

export function rmsReturnRequests(startDate: any, endDate: any) {
  return axios.get(
    `${
      process.env.REACT_APP_BASE_INSIGHTS_URL
    }/rms_insights/return_requests/group_by?from_date=${startDate}&to_date=${endDate}&select=return_type&limit=10&offset=0&token=${localStorage.getItem(
      "authstore"
    )}`
  );
}

export function rmsReasonSplit(startDate: any, endDate: any) {
  return axios.get(
    `${
      process.env.REACT_APP_BASE_INSIGHTS_URL
    }/rms_insights/return_requests/refund_vs_exchange?from_date=${startDate}&to_date=${endDate}&select=customer_reason_label&limit=10&offset=0&token=${localStorage.getItem(
      "authstore"
    )}`
  );
}

export function rmsReturnWarehouse(startDate: any, endDate: any) {
  return axios.get(
    `${
      process.env.REACT_APP_BASE_INSIGHTS_URL
    }/rms_insights/return_requests_line_items/group_by?from_date=${startDate}&to_date=${endDate}&select=return_warehouse_id&limit=10&offset=0&token=${localStorage.getItem(
      "authstore"
    )}`
  );
}

export function rmsProductCategories(startDate: any, endDate: any) {
  return axios.get(
    `${
      process.env.REACT_APP_BASE_INSIGHTS_URL
    }/rms_insights/return_requests_line_items/refund_vs_exchange?from_date=${startDate}&to_date=${endDate}&select=product_title&limit=10&offset=0&token=${localStorage.getItem(
      "authstore"
    )}`
  );
}

export function rmsProductsSku(startDate: any, endDate: any) {
  return axios.get(
    `${
      process.env.REACT_APP_BASE_INSIGHTS_URL
    }/rms_insights/return_requests_line_items/refund_vs_exchange?from_date=${startDate}&to_date=${endDate}&select=product_sku&limit=10&offset=0&token=${localStorage.getItem(
      "authstore"
    )}`
  );
}

export function rmsLocation(startDate: any, endDate: any) {
  return axios.get(
    `${
      process.env.REACT_APP_BASE_INSIGHTS_URL
    }/rms_insights/return_requests/refund_vs_exchange?from_date=${startDate}&to_date=${endDate}&select=customer_address_state&limit=10&offset=0&token=${localStorage.getItem(
      "authstore"
    )}`
  );
}

export function rmsTotalRefundAmount(startDate: any, endDate: any) {
  return axios.get(
    `${
      process.env.REACT_APP_BASE_INSIGHTS_URL
    }/rms_insights/return_requests/refund_amount_by_refund_status?from_date=${startDate}&to_date=${endDate}&token=${localStorage.getItem(
      "authstore"
    )}`
  );
}

export function rmsShipVsPickup(startDate: any, endDate: any) {
  return axios.get(
    `${
      process.env.REACT_APP_BASE_INSIGHTS_URL
    }/rms_insights/return_requests/self_ship_vs_merchant_pickup?from_date=${startDate}&to_date=${endDate}&token=${localStorage.getItem(
      "authstore"
    )}`
  );
}

// Segmentation

export function rulesSubmit(buildQuery: any) {
  return axios.post(
    "http://188.34.195.118:8092/api/v1/webhooks/filter/5",
    buildQuery,
    {
      params: {
        merchant_id: 5,
      },
    }
  );
}

export function pollingResults(filter_id: string) {
  return axios.post(
    `http://188.34.195.118:8092/api/v1/webhooks/poll/5?merchant_id=5&filter_id=${filter_id}`
  );
}

export function downloadCSV(filter_id: string) {
  return axios.get(
    `http://188.34.195.118:8092/api/v1/webhooks/get_audience_csv?merchant_id=5&filter_id=${filter_id}`
  );
}

export function fetchData(filter_id: string) {
  return axios.get(
    `http://188.34.195.118:8092/api/v1/webhooks/fetch_filter_data?merchant_id=5&filter_id=${filter_id}`
  );
}

// 1C Insights

export function fetchCheckoutData(startDate: any, endDate: any) {
  return axios.get(
    `${process.env.REACT_APP_BASE_INSIGHTS_URL}/checkout/onecheckout_analytics`,
    {
      params: {
        start_date: `${startDate}`,
        end_date: `${endDate}`,
        token: `${localStorage.getItem("authstore")}`,
      },
    }
  );
}

export function syncCheckoutData(startDate: any, endDate: any) {
  return axios.get(
    `${process.env.REACT_APP_BASE_INSIGHTS_URL}/checkout/sync_onecheckout_analytics`,
    {
      params: {
        start_date: `${startDate}`,
        end_date: `${endDate}`,
        token: `${localStorage.getItem("authstore")}`,
      },
    }
  );
}
