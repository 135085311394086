/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { getCSSVariableValue } from "../../../_metronic/assets/ts/_utils";
import { Inbox } from "../../../_metronic/partials/widgets/charts/Inbox";
import { LocationChart } from "../../../_metronic/partials/widgets/charts/LocationChart";
import { ProductCategory } from "../../../_metronic/partials/widgets/charts/ProductCategory";
import { ReasonSplit } from "../../../_metronic/partials/widgets/charts/ReasonSplit";
import { RefundData } from "../../../_metronic/partials/widgets/charts/RefundData";
import { ReturnRequestData } from "../../../_metronic/partials/widgets/charts/ReturnRequest";
import { ShipVsPickupData } from "../../../_metronic/partials/widgets/charts/ShipVsPickup";
import { ReturnWareHouseData } from "../../../_metronic/partials/widgets/charts/WareHouse";
import { Checkout } from "../../../_metronic/partials/widgets/charts/Checkout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

type Props = {
  colors: any;
};

const DashboardPage: FC<Props> = (colors: any) => (
  <>
    {/* Title with Bread crumbs */}
    <ToastContainer />

    <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6 px-6 px-lg-12">
      <div
        id="kt_app_toolbar_container"
        className="container-fluid d-flex flex-column flex-md-row justify-content-between align-items-center"
      >
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
            Insights
          </h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              <a href="/" className="text-muted text-hover-primary">
                Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <span className="bullet bg-gray-400 w-5px h-2px"></span>
            </li>
            <li className="breadcrumb-item text-muted">Insights</li>
          </ul>
        </div>
      </div>
    </div>

    <div className="row g-5 g-xl-10 mb-5 mb-xl-10 px-6 px-lg-18">
      <Inbox />
    </div>

    <div className="row g-5 g-xl-10 mb-5 mb-xl-10 px-6 px-lg-18">
      <Checkout />
    </div>

    <div className="row g-5 g-xl-10 mb-5 mb-xl-10 px-5 px-lg-16">
      <div className="col-xxl-4">
        <RefundData className="card-xxl-stretch mb-5 mb-xl-10" />
      </div>
      <div className="col-xxl-4">
        <ReturnWareHouseData className="card-xxl-stretch mb-5 mb-xl-10" />
      </div>
      <div className="col-xxl-4">
        <ReturnRequestData className="card-xxl-stretch mb-5 mb-xl-10" />
      </div>
    </div>

    <div className="row g-5 g-xl-10 mb-5 mb-xl-10 px-5 px-lg-16">
      <div className="col-xxl-7">
        <ReasonSplit className="card-xxl-stretch mb-5 mb-xl-10" />
      </div>
      <div className="col-xxl-5">
        <ShipVsPickupData className="card-xxl-stretch mb-5 mb-xl-10" />
      </div>
    </div>

    <div className="row g-5 g-xl-10 mb-5 mb-xl-10 px-6 px-lg-18">
      <ProductCategory className="card-xxl-stretch mb-5 mb-xl-10" />
    </div>

    <div className="row g-5 g-xl-10 mb-5 mb-xl-10 px-6 px-lg-18">
      <LocationChart className="card-xxl-stretch mb-5 mb-xl-10" />
    </div>
  </>
);

const DashboardWrapper: FC = () => {
  const color1 = getCSSVariableValue("--kt-danger");
  const color2 = getCSSVariableValue("--kt-primary");
  const color3 = getCSSVariableValue("--kt-success");
  const color4 = getCSSVariableValue("--kt-warning");
  const color5 = getCSSVariableValue("--kt-info");
  const color6 = getCSSVariableValue("--kt-secondary");
  const color7 = getCSSVariableValue("--kt-text-red-60");
  const color8 = getCSSVariableValue("--kt-text-blue-60");
  const color9 = getCSSVariableValue("--kt-text-green-60");
  const color10 = getCSSVariableValue("--kt-text-yellow-60");

  const colors = [
    color1,
    color2,
    color3,
    color4,
    color5,
    color6,
    color7,
    color8,
    color9,
    color10,
    color4,
  ];

  return (
    <>
      <DashboardPage colors={colors} />
    </>
  );
};

export { DashboardWrapper };
