export const dateOperators = [
  { name: "select", label: "Select operator" },
  { name: "eq", label: "on" },
  { name: "gt", label: "after" },
  { name: "lt", label: "before" },
  { name: "between", label: "between" },
  { name: "notBetween", label: "not between" }
];

export const countOperators = [
  { name: "select", label: "Select operator" },
  { name: "lt", label: "Less than" },
  { name: "gt", label: "Greater than" },
  { name: "lte", label: "Less than or equal to" },
  { name: "gte", label: "Greater than or equal to" },
  { name: "eq", label: "equals" },
  { name: "between", label: "between" },
  { name: "notBetween", label: "not between" }
];

export const statusOperators = [
  { name: "select", label: "Select operator" },
  { name: "eq", label: "equals" },
  { name: "neq", label: "not equals" },
];

export const stringOperators = [
  { name: "select", label: "Select operator" },
  { name: "eq", label: "equals" },
  { name: "neq", label: "not equals" },
  { name: "cs", label: "contains" },
  { name: "does_not_contain", label: "does not contain" },
  { name: "null", label: "is null" },
  { name: "not_null", label: "is not null" },
  { name: "in", label: "in" },
  { name: "not_in", label: "not in" },
  { name: "begins_with", label: "begins with" },
  { name: "does_not_begin_with", label: "does not begin with" },
  { name: "ends_with", label: "ends with" },
  { name: "does_not_end_with", label: "does not end with" },
];

export const standardOperators = [
  { name: "select", label: "Select operator" }
]
