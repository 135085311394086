import { useLocation } from "react-router-dom";

function Validate() {
      const hostname = window.location.hostname;
      let origin;
      switch (hostname) {
        case 'localhost':
        case 'insights.test.logisy.in': {
          origin = `https://test.logisy.in`
          break;
        }
        case 'insights.logisy.tech': {
          origin = `https://logisy.tech`
          break;
        }
        case 'insights.stage.logisy.tech': {
          origin = `https://stage.logisy.tech`
          break;
        }
        default:
          origin = `https://test.logisy.in`;
          break;
      }
      const search = useLocation().search;
      const auth = new URLSearchParams(search).get('token') || localStorage.getItem('authstore');
      if(auth === null || auth === "" || auth === undefined){
          window.location.href=`${origin}/store/orders`;
      }
      else{
        localStorage.setItem('authstore', auth);
        window.location.href = '/dashboard';
      }
    return (
        <div>
        </div>
    )
}

export default Validate